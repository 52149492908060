<template>
  <BtnTemplate
    class="facebook"
    :class="{ disabled, small }"
    data-test-id="loginFormFacebookLoginCTA"
    @click="$emit('click', $event)"
  >
    <span class="btnText">
      <slot />
    </span>
  </BtnTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import BtnTemplate from './template.vue'

export default defineComponent({
  components: {
    BtnTemplate,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
.btnText {
  @apply tw-text-white;
}

.facebook {
  @apply tw-bg-brands-facebook tw-shadow-round;

  &:hover:not(.disabled) {
    @apply tw-bg-blue-600;
  }

  &.disabled {
    @apply tw-opacity-50;
  }
}
</style>
